.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    max-width: 500px;
    width: 90%;
  }
  
  /* ボタンスタイル */
  .modal-content button {
    margin-top: 10px;
    padding: 5px 15px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  .modal-content a {
    color: #007bff; /* リンクの色 */
    text-decoration: none; /* アンダーラインを消す */
    font-weight: bold; /* フォントを太くする */
  }
  
  /* ホバー時のスタイル */
  .modal-content a:hover {
    color: #0056b3; /* ホバー時の色 */
    text-decoration: underline; /* ホバー時にアンダーラインを表示 */
  }